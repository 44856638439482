
import { mapGetters } from 'vuex';

// import SVGCertificate from '~/assets/svg/certificate.svg';
export default {
  // components: { SVGCertificate },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters({
      isMusicActive: 'ui/isMusicActive'
    })
  }
};
