import { render, staticRenderFns } from "./Page.vue?vue&type=template&id=dcf65fc8&scoped=true&"
import script from "./Page.vue?vue&type=script&lang=js&"
export * from "./Page.vue?vue&type=script&lang=js&"
import style0 from "./Page.vue?vue&type=style&index=0&id=dcf65fc8&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dcf65fc8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ContentHomeHero: require('/vercel/path0/components/content/HomeHero.vue').default,AnimationGsapStretchXTranslate: require('/vercel/path0/components/elements/Animations/GsapStretchXTranslate.vue').default,AnimationCustomFadeIn2: require('/vercel/path0/components/elements/Animations/CustomFadeIn2.vue').default,Button: require('/vercel/path0/components/elements/Button.vue').default,PageFooter: require('/vercel/path0/components/page/Footer.vue').default})
