
export default {
  props: {
    distance: {
      type: Number,
      default: 1000
    }
  },
  data() {
    return {
      timeline: null,
      rect: null,
      docEl: null
    };
  },
  mounted() {
    this.rect = this.$refs.IntroReadText.getBoundingClientRect();
    this.docEl = document.documentElement;
    this.animateOnScroll();
    window.addEventListener('resize', this.onResize);

    // TODO: I think Nuxt load stuff in the DOM in the background and thus it does not trigger
    // the scrolltriger refresh function automatically and don't recalculate the start position.
    // When navigating between route it makes the scrolltrigger not working
    // It would be great to find a final solution for this problem.
    // Current workaround I found:
    // hint: I think the problem might come from nuxt pagetransitions...
    setTimeout((a) => {
      this.timeline.scrollTrigger.refresh();
    }, 600);
  },
  beforeDestroy() {
    this.timeline.kill();
  },
  methods: {
    onResize() {
      // We destroy and recreate the timeline when the window is resized
      // I find it ugly, better way must existe to handle that
      // Also not working 100% on resize, calculation not perfect :/
      this.timeline.kill();
      this.$nextTick(() => this.animateOnScroll());
    },

    animateOnScroll() {
      const startAnimAt = window.innerWidth < 1024 ? 'top 40%' : 'top 80%';
      // helpful: https://greensock.com/docs/v3/Plugins/ScrollTrigger
      this.timeline = this.$gsap.timeline({
        scrollTrigger: {
          trigger: this.$refs.IntroReadText.firstChild,
          // start when top of element is at the bottom of the viewport
          start: startAnimAt,
          end: 'bottom top',
          //   Scrub allow to smooth the animation when scroll stop
          scrub: 0,
          invalidateOnRefresh: true
        }
      });

      this.timeline.fromTo(
        this.$refs.IntroReadText.firstChild,
        {
          scaleY: 0,
          transformOrigin: 'bottom'
        },
        {
          scaleY: this.distance,
          transformOrigin: 'bottom'
        },
        '-=0.1'
      );
    }
  }
};
