
// @group document
import SVGtransitionBorder from '../../assets/svg/transitionBorder.svg';

export default {
  name: 'PageComponent',
  components: {
    SVGtransitionBorder
  },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      show: false
    };
  },
  mounted() {
    // Useful resource to understand this line:
    // https://dmitripavlutin.com/vue-next-tick/
    this.$nextTick(() => (this.show = true));
  },
  methods: {
    calculateHeight() {
      // Calculate the content height of the iframe
      // const iframe = this.$refs.chopfab;
      // const contentHeight = iframe.contentWindow.document.body.scrollHeight;
      // console.log(contentHeight);
      // Set the iframe's height dynamically
      // iframe.style.height = `${contentHeight}px`;
    }
  }
};
